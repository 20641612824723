import React, { useContext, useEffect } from "react"
import { Link } from "gatsby"
import AppContext from "../contexts/app/context"

// const { GATSBY_API_HOST } = process.env
const GATSBY_API_HOST = "https://hamzatanjicherkaoui.com/api"
export default function cart() {
  const { cart: products, updateCart, updateQuantity } = useContext(AppContext)

  useEffect(() => {
    if (!products.length) {
      const jsonCart = localStorage.getItem("cart")
      const localCart = JSON.parse(jsonCart)
      updateCart(localCart || [])
    }
  }, [])

  return (
    <>
      <div className="w-3/4 mx-auto bg-white shadow-md rounded-lg  mt-12 mb-12">
        <div className="px-4 py-4">
          <h2 className="text-xl font-semibold text-gray-800">Your Cart</h2>
          {products.map(product => (
            <div className="mt-4">
              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center">
                  <img
                    className="w-12 h-12 rounded"
                    src={`${GATSBY_API_HOST}/download/${product.image}`}
                    alt="Product 1"
                  />
                  <div className="ml-2">
                    <h3 className="text-gray-800">{product.name}</h3>
                    <p className="text-gray-600">Price: ${product.price}</p>
                  </div>
                </div>
                <div className="flex items-center">
                  <button
                    className="text-gray-500 hover:text-red-500 focus:outline-none"
                    onClick={() => updateQuantity(product.id, 1)}
                  >
                    +
                  </button>
                  <p className="text-gray-600 mx-2"> {product.quantity}</p>
                  <button
                    className="text-gray-500 hover:text-green-500 focus:outline-none"
                    onClick={() => updateQuantity(product.id, -1)}
                  >
                    <svg className="w-4 h-4 fill-current" viewBox="0 0 24 24">
                      <path d="M19 13H5v-2h14v2z" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="px-4 py-2 bg-gray-100 flex flex-col justify-end">
          <div className="flex w-1/6 items-center justify-between self-end">
            <p className="text-purple-600 font-bold ">Total:</p>
            <p className="text-xl font-semibold text-gray-800">
              $
              {products
                .map(e => e.price * e.quantity)
                .reduce((a, b) => a + b, 0)}
            </p>
          </div>
          <Link
            to="/checkout"
            className="w-1/6 mt-4 text-center bg-purple-500 hover:bg-purple-600 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline self-end justify-end"
          >
            Checkout
          </Link>
        </div>
      </div>
    </>
  )
}

export function Head() {
  return (
    <meta
      httpEquiv="Content-Security-Policy"
      content="upgrade-insecure-requests"
    />
  )
}
